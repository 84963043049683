import 'nette.ajax.js'

import 'bootstrap/js/src/util';
import 'bootstrap/js/src/scrollspy';
import 'bootstrap/js/src/collapse';

import './modules/back_top';
import './modules/scroll';

import '../../styles/web.scss'

import './pages/homepage';

$(function () {
  $.nette.init();
  $('body').scrollspy({
    target: '#navbar',
    offset: 80,
  });
});

function smoothscroll(event){
  event.preventDefault();
  const targetId = event.currentTarget.getAttribute("href");

  window.scrollTo({
    top: document.querySelector(targetId).offsetTop + 60,
    behavior: "smooth"
  })
}

$(document).on('click', '[data-scroll]', smoothscroll);

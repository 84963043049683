$(window).scroll(function() {
  if ($(this).scrollTop() > 1) {
    $('.dmtop').css({
      bottom: "75px"
    });
  } else {
    $('.dmtop').css({
      bottom: "-100px"
    });
  }
});
